import {Injectable} from "@angular/core";
import { Apis } from "environments/api";
import {Observable} from "rxjs";

import {HttpClientService} from "./http-client.service";

@Injectable({providedIn: "root"})
export class ApiService {
  constructor(private http : HttpClientService) { //private tokenService:TokenService
  }

  // authenticate
  public login(Username : string, Password : string): Observable<any> {
    let body = {
      username: Username,
      password: Password
    };

    return this.http.send(Apis.APILogin, false, body);
  }




  public updateAccount(email, username,profili): Observable<any> {
    let body = {
      email: email,
      username: username,
      profili:profili
    };

    return this.http.send(Apis.APIUpdateAccount, false, body);
  }

  public changePassword(email, password): Observable<any> {
    let body = {
      email: email,
      password: password
    };

    return this.http.send(Apis.APIChangePassword, false, body);
  }

  public sendPaymentsMail(payments): Observable<any> {
    return this.http.send(Apis.APIPaymentMail, false, payments);
  }

  public loginQrCode(qrcode : string): Observable<any> {
    let urlParams = {
      key: qrcode
    };

    return this.http.sendWithUrlParamAppend(Apis.APILoginQRCode, false, urlParams);
  }

  /*public registration(registration:Registration): Observable<any> {

    return this.http.send(Apis.APIRegistration, true, registration);
  }*/

  public getUserQrcode(qrcode): Observable<any> {
    let urlParams = {
      key: qrcode
    };

    return this.http.sendWithUrlParamAppend(Apis.APIGetUserQrcode, false, urlParams);
  }

  public getUserImage(id): Observable<any> {
    //p.sendWithUrlParam(Apis.APIDownloadDocumenti, { 'id': id }, null, null, null, 'blob');
    return this.http.sendWithUrlParam(Apis.APIGetUserImage, true, {
      id: id
    }, null, null, null, "blob");
  }

  // Notification
  public getNotifications(): Observable<any> {
    return this.http.send(Apis.APIGetNotifications, true);
  }

  // profile

  public getProfile(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetProfile, true, {id: id});
  }

  public getUnion(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetUnion, true, {id: id});
  }

  public udpateProfile(id, user): Observable<any> {
    return this.http.send(Apis.APIUpdateProfile, true, user);
    // return this.http.sendWithUrlParam(Apis.APIUpdateProfile, true,{"id":id}, user);
  }

  public refreshToken(refreshToken : string): Observable<any> {
    let body = {
      refreshToken: refreshToken
    };

    return this.http.send(Apis.APIRefeshToken, false, body);
  }

  //Program
  public getProgram(): Observable<any> {
    return this.http.send(Apis.APIGetProgram, false);
  }

  public getSchedule(): Observable<any> {
    return this.http.send(Apis.APIGetSchedule, false);
  }

  public getGenericJson(fileName): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetGenericFile, false, {fileName: fileName});
  }

  //ZONE
  public getZone(): Observable<any> {
    return this.http.send(Apis.APIGetZone, true);
  }

  public logout(): Observable<any> {
    let urlParams = {
      disconnectAll: "true",
      username: "" //this.tokenService.getUsername()
    };

    return this.http.sendWithUrlParamAppend(Apis.APILogout, false, urlParams);
  }

  public downloadPdf(id): Observable<any> {
    return this.http.send(Apis.APIDownloadPdf, false);
  }

  //*******
  //****
  //*/

  public getItinerari(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetItinerari, true, {id: id});
  }

  public getItinerario(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetItinerario, true, {id: id});
  }

  public addItinerario(itinerario): Observable<any> {
    return this.http.send(Apis.APIAddItinerario, true, itinerario);
  }

  public updateItinerario(itinerario): Observable<any> {
    return this.http.send(Apis.APIUpdatetItinerario, true, itinerario);
  }

  public getImmagini(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetImmagini, true, {id: id});
  }

  public deleteItinerario(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIDeleteItinerario, true, {id: id});
  }

  

  // BOE

  public getBoeAmp(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetBoeAmp, true,{id: id});
  }

  public setBoa(boa): Observable<any> {
    return this.http.send(Apis.APISetBoe, true, boa);
  }

  public liberaBoa(boa): Observable<any> {
    return this.http.send(Apis.APILiberaBoa, true, boa);
  }

  public occupaBoa(boa): Observable<any> {
    return this.http.send(Apis.APIOccupaBoa, true, boa);
  }
  


  public getBoePrenotate(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetBoePrenotate, true,{id: id});
  }

  public getBoePrenotateList(): Observable<any> {
    return this.http.send(Apis.APIGetBoePrenotateList, true);
  }

  
  public getExportTremiti(): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIExportTremiti, true, );
  }
  public getBoe(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetBoe, true, {id: id});
  }

  public getBoePrenotateDAY(amp_id,data_inizio): Observable<any> {

    let body = {
      amp_id:amp_id,
      data_inizio:data_inizio
    };

    return this.http.send(Apis.APIGetBoePrenotateDAY, false, body);


  
  }

  public getDatiMonitoraggio(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetDatiMonitoraggio, true, {id: id});
  }

  public getAmp(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetAmp, true, {id: id});
  }

  public setAmp(amp): Observable<any> {
    return this.http.send(Apis.APIUpdateAmp, true, amp);
  }




  

  public getAmpKML(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetAmpKML, true, {id: id});
  }

  // AMPS

  public getAMPS(): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetAMPS, true);
  }

  public getPerimetroAmp(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetPerimetroAmp, true, {id: id});
  }

  // Zone Amp
  public getZoneAmp(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetZoneAmp, true, {id: id});
  }

  // profili utenti

  public getProfiliUtente(): Observable<any> {
    return this.http.send(Apis.APIGetProfiliUtente, true);
  }

  //segnalazioni

  public getSegnalazioni(user, idAmp): Observable<any> {
    return this.http.send(Apis.APIGetSegnalazioni, true, {
      user: user,
      amp: idAmp
    });
  }

  public getAllSegnalazioni(id): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetAllSegnalazioni, true, {id: id});
  }



  // PRENOTAZIONI
  public getPrenotazioni( amp : number): Observable<any> {
 

    return this.http.sendWithUrlParam(Apis.APIGetPrenotazioni, false, {id:amp});
  }

  // User

  public getUser(email): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetUser, true, {email: email});
  }

  public getUserProfile(email): Observable<any> {
    return this.http.sendWithUrlParam(Apis.APIGetUserProfilo, true, {email: email});
  }

  public changePrenotazioni(user : string, password : string): Observable<any> {
    let body = {
      user: user,
      password: password
    };

    return this.http.send(Apis.APIChangePassword, false, body);
  }

  public deleteUser(email : string): Observable<any> {
    let body = {
      email: email
     
    };

    return this.http.send(Apis.APIDeleteUser, false, body);
  }

  public getUsersPositions(): Observable<any> {
    return this.http.send(Apis.APIGetUsersPosition, true, );
  }


}
