import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor() { }



  saveIdAmp(id){
    sessionStorage.setItem('ID',id);
  }

  getIdAmp(){
    return sessionStorage.getItem('ID');
  }

  saveNomeAmp(amp){
    sessionStorage.setItem('AMP',amp);
  }

  getNomeAmp(){
    return sessionStorage.getItem('AMP');
  }
}
